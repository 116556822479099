<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">Simple Pay</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col v-if="!isOk" sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Hola! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Por favor ingresa tu nueva contraseña
          </b-card-text>

          <!-- form -->
          <validation-observer ref="recoveryValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="recovery-password">Nueva contraseña</label>
                  <!-- <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Olvidaste tu Contraseña?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|min:6"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="recovery-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="recovery-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                :disabled="disabledBtn"
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Enviar
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
        <b-col v-else sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Genial 👍
          </b-card-title>
          <b-card-text class="mb-2">
            Tu contraseña ha sido cambiada con éxito! 🎉
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      disabledBtn: false,
      isOk: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.recoveryValidation.validate().then((success) => {
        if (success) {
          this.recoveryPassword();
        }
      });
    },
    recoveryPassword() {
      // Loading
      // this.$vs.loading()
      console.log(this.$route.query.ticket);
      const payload = {
        ticket: this.$route.query.ticket,
        newPassword: this.password,
      };

      this.$store
        .dispatch("auth/recoveryPassword", payload)
        .then(() => {
          // this.$vs.loading.close()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "OK",
              text: "Su contraseña a sido actualizada con exito!! ",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isOk = true;
          this.disabledBtn = true;
        })
        .catch((error) => {
          // this.$vs.loading.close()
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: error,
              icon: "AlertCircleIcon",
              variant: "error",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
